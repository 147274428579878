import styled from "styled-components";
import "react-confirm-alert/src/react-confirm-alert.css";
import SimpleConfirmation from "../components/widgets/simple-confirmation";
import { confirmAlert } from "react-confirm-alert";
import loadingAni from "../resources/loading.gif";

//OOS Dev Server
// export const resturl = "https://oos.asttimbers.com.au/tistock/rest/v1";
// export const fileurl = "https://oos.asttimbers.com.au/tistockfile/stock";

//B2B Official Server
export const resturl = "https://b2b.asttimbers.com.au/tistock/rest/v1";
export const fileurl = "https://b2b.asttimbers.com.au/tistockfile/stock";

/* AST OOS Sandbox server */
// export const resturl = "https://sandbox.asttimbers.com.au/tistock/rest/v1";
// export const fileurl = "https://sandbox.asttimbers.com.au/tistockfile/stock";

/* Jerry Local REST server */
// export const resturl = "http://localhost:8085/tistock/rest/v1";
// export const fileurl = "http://localhost:8086/stock";

/* AST OOS Local server */
// export const resturl = "http://localhost:8080/tistock/rest/v1/";
// export const fileurl = "http://localhost:8080/tistockfile/stock";

export const maxItemQty = 250;

export const popupMaxItemQty = () => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <SimpleConfirmation
          title="You have reached the order max. limit"
          message="You have reached the order maximum quantity limit for the product(s) due to our B2B company's policy. Please contact your local sales rep, or our customer service team on 1300 901 355 to proceed with this order."
          onClose={onClose}
        />
      );
    },
  });
};

let widthFactor = 0.65;
if (window.innerWidth <= 640) {
  widthFactor = 0.96;
} else if (window.innerWidth > 640 && window.innerWidth < 800) {
  widthFactor = 0.92;
} else if (window.innerWidth >= 800 && window.innerWidth <= 3440) {
  widthFactor = 0.45 + (Math.abs(3440 - window.innerWidth) / 2640) * 0.45;
}
const contentWidth = Math.round(window.innerWidth * widthFactor);

const mobileResizeFactor = window.innerWidth <= 640 ? 2.2 : 1;

export const theme = {
  astGreen: "#55622b",
  astGreenHover: "#697838",
  astGreenLight: "#ccd0bf",
  charcoal: "#373731",
  biege: "#eff0ed",
  darkerBiege: "#c8c8c6",
  bright: "#ffffff",
  lightBlue: "#008bff",
  noticeRed: "#ff2f00",
  warningYellow: "#ffb700",
  infoPurple: "#8911ff",
  passGreen: "#00ae00",
  waitingPink: "#ef4b81",
  draftPantone: "#caa2dd",
  backPantone: "#b33d26",

  basicWidth: 1156,
  contentWidth: contentWidth,
  proportion: contentWidth / 1156,
  mobileResizeFactor: mobileResizeFactor,

  ultraBoldFont: "HelveticaNeueLTStd-HvCn",
  boldFont: "HelveticaNeueLTStd-Bd",
  romanFont: "HelveticaNeueLTStd-Roman",
  midFont: "HelveticaNeueLTStd-Md",
  lightFont: "HelveticaNeueLTStd-Lt",
  ultraLightFont: "HelveticaNeueLTStd-ThIt",
};

export const assembleStyles = (props, theme) => {
  let styles = "";
  styles += props.xmarginTop
    ? "margin-top: " +
      props.xmarginTop * theme.proportion * mobileResizeFactor +
      "px;"
    : "";
  styles += props.xmarginBottom
    ? "margin-bottom: " +
      props.xmarginBottom * theme.proportion * mobileResizeFactor +
      "px;"
    : "";
  styles += props.xmarginLeft
    ? "margin-left: " +
      props.xmarginLeft * theme.proportion * mobileResizeFactor +
      "px;"
    : "";
  styles += props.xmarginRight
    ? "margin-right: " +
      props.xmarginRight * theme.proportion * mobileResizeFactor +
      "px;"
    : "";
  styles += props.xpadding
    ? "padding: " +
      props.xpadding * theme.proportion * mobileResizeFactor +
      "px;"
    : "";
  styles += props.xpaddingLeft
    ? "padding-left: " +
      props.xpaddingLeft * theme.proportion * mobileResizeFactor +
      "px;"
    : "";
  styles += props.xheight
    ? "height: " + props.xheight * theme.proportion * mobileResizeFactor + "px;"
    : "";
  styles += props.xminHeight
    ? "min-height: " +
      props.xminHeight * theme.proportion * mobileResizeFactor +
      "px;"
    : "";
  styles += props.xlineHeight
    ? "line-height: " +
      props.xlineHeight * theme.proportion * mobileResizeFactor +
      "px;"
    : "";
  styles += props.xwidth
    ? "width: " + props.xwidth * theme.proportion * mobileResizeFactor + "px;"
    : "";
  styles += props.xbackgroundColor
    ? "background-color: " + theme[props.xbackgroundColor] + ";"
    : "";
  styles += props.xcolor ? "color: " + theme[props.xcolor] + ";" : "";
  styles += props.xfontSize
    ? "font-size: " +
      props.xfontSize * theme.proportion * mobileResizeFactor +
      "px;"
    : "";
  styles += props.xtextAlign ? "text-align: " + props.xtextAlign + ";" : "";
  return styles;
};

export const FullWHContainer = styled.div`
  width: 100%;
  min-height: 100%;
  ${(props) => assembleStyles(props, theme)};
`;

export const FullWidthContainer = styled.div`
  width: 100%;
  ${(props) => assembleStyles(props, theme)};
`;

export const CCContainer = styled.div`
  width: ${contentWidth}px;
  margin: auto;
`;

export const FullWHImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const H2Title = styled.h2`
  ${(props) => assembleStyles(props, theme)};
`;

export const H3Title = styled.h3`
  font-family: ${theme.boldFont};
  ${(props) => assembleStyles(props, theme)};
`;

export const H5Title = styled.h5`
  font-family: ${theme.boldFont};
  ${(props) => assembleStyles(props, theme)};
`;

export const H6Title = styled.h6`
  font-family: ${theme.boldFont};
  ${(props) => assembleStyles(props, theme)};
`;

export const PGeneral = styled.p`
  font-family: ${theme.midFont};
  ${(props) => assembleStyles(props, theme)};

  & a {
    color: ${theme.lightBlue};
    font-family: ${theme.boldFont};
    text-decoration: underline;
  }
`;

export const PLGeneral = styled.p`
  font-family: ${theme.lightFont};
  ${(props) => assembleStyles(props, theme)};
`;

export const SpanBGeneral = styled.span`
  font-family: ${theme.boldFont};
  ${(props) => assembleStyles(props, theme)};
`;

export const SpanLGeneral = styled.span`
  font-family: ${theme.lightFont};
  ${(props) => assembleStyles(props, theme)};
`;

export const SpanMGeneral = styled.span`
  font-family: ${theme.midFont};
  ${(props) => assembleStyles(props, theme)};
`;

export const FormGeneral = styled.form`
  ${(props) => assembleStyles(props, theme)};
`;

export const LabelGeneral = styled.label`
  font-family: ${theme.boldFont};
  ${(props) => assembleStyles(props, theme)};
`;

export const InputGeneral = styled.input`
  font-family: ${theme.midFont};
  border: none;
  background-color: ${theme.biege};
  height: ${theme.proportion * 27}px;
  padding: 0 ${theme.proportion * 10}px;
`;

export const SelectGeneral = styled.select`
  font-family: ${theme.midFont};
  border: none;
  background-color: ${theme.biege};
  padding: 0 ${theme.proportion * 10}px;
  ${(props) => assembleStyles(props, theme)};

  & * {
    background-color: ${theme.charcoal};
    color: ${theme.bright};
  }
`;

export const TextareaGeneral = styled.textarea`
  font-family: ${theme.midFont};
  border: none;
  background-color: ${theme.biege};
  padding: ${theme.proportion * 10}px;
`;

export const ButtonGeneral = styled.button`
  font-family: ${theme.boldFont};
  color: ${theme.bright};
  border: none;
  border-radius: 0;
  background-color: ${theme.astGreen};
  cursor: pointer;
  ${(props) => assembleStyles(props, theme)};

  &:hover {
    background-color: ${theme.astGreenHover};
  }
`;

export const ButtonFuncG = styled.button`
  font-family: ${theme.boldFont};
  color: ${theme.bright};
  border: 1px solid ${theme.astGreen};
  border-radius: 0;
  background-color: ${theme.astGreen};
  cursor: pointer;
  font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  padding: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  ${(props) => assembleStyles(props, theme)};

  &:hover {
    background-color: ${theme.astGreenHover};
  }
`;

export const ButtonFuncR = styled.button`
  font-family: ${theme.boldFont};
  color: ${theme.astGreen};
  border: 1px solid ${theme.astGreen};
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  padding: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  ${(props) => assembleStyles(props, theme)};

  &:hover {
    background-color: ${theme.biege};
  }
`;

export const LinkButtonG = styled.a`
  font-family: ${theme.boldFont};
  color: ${theme.bright};
  border: 1px solid ${theme.astGreen};
  border-radius: 0;
  background-color: ${theme.astGreen};
  cursor: pointer;
  font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  padding: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  ${(props) => assembleStyles(props, theme)};

  &:hover {
    background-color: ${theme.astGreenHover};
  }
`;

export const LinkButtonR = styled.a`
  font-family: ${theme.boldFont};
  color: ${theme.astGreen};
  border: 1px solid ${theme.astGreen};
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  padding: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  ${(props) => assembleStyles(props, theme)};

  &:hover {
    background-color: ${theme.biege};
  }
`;

export const TableGeneral = styled.table`
  width: 100%;
  overflow-x: auto;
  ${(props) => assembleStyles(props, theme)};
`;

export const ThGeneral = styled.th`
  font-family: ${theme.boldFont};
  font-size: ${theme.proportion * 14 * theme.mobileResizeFactor}px;
  line-height: ${theme.proportion * 32 * theme.mobileResizeFactor}px;
  text-align: ${(props) => (props.textalign ? props.textalign : "left")};
  border-bottom: 1px solid ${theme.biege};
  ${(props) => assembleStyles(props, theme)};

  @media only screen and (max-width: 640px) {
    line-height: ${theme.proportion * 20 * theme.mobileResizeFactor}px;
    padding: ${theme.proportion * 6 * theme.mobileResizeFactor}px
      ${theme.proportion * 2 * theme.mobileResizeFactor}px;
  }
`;

export const TdGeneral = styled.td`
  font-family: ${theme.midFont};
  font-size: ${theme.proportion * 14 * theme.mobileResizeFactor}px;
  line-height: ${theme.proportion * 32 * theme.mobileResizeFactor}px;
  text-align: ${(props) => (props.textalign ? props.textalign : "left")};
  border-bottom: 1px solid ${theme.biege};
  ${(props) => assembleStyles(props, theme)};

  @media only screen and (max-width: 640px) {
    line-height: ${theme.proportion * 20 * theme.mobileResizeFactor}px;
    padding: ${theme.proportion * 6 * theme.mobileResizeFactor}px
      ${theme.proportion * 2 * theme.mobileResizeFactor}px;
  }
`;

export const TableDecorRow = styled.tr`
  border-bottom: 1px solid ${theme.biege};
  ${(props) => assembleStyles(props, theme)};
`;

export const DivDecorRow = styled.div`
  border-bottom: 1px solid ${theme.biege};
  ${(props) => assembleStyles(props, theme)};
`;

export const ExlinkIcon = styled.img`
  width: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  vertical-align: middle;
  margin-right: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  display: inline-block;
`;

export const EditPenIcon = styled.img`
  width: ${theme.proportion * 14 * theme.mobileResizeFactor}px;
  margin-left: ${theme.proportion * 5 * theme.mobileResizeFactor}px;
  vertical-align: baseline;
  display: inline-block;
  cursor: pointer;

  @media only screen and (max-width: 640px) {
    width: ${theme.proportion * 20 * theme.mobileResizeFactor}px;
    vertical-align: top;
  }
`;

export const EditPenGreyIcon = styled(EditPenIcon)`
  cursor: default;
`;

export const DocOpenIcon = styled(EditPenIcon)`
  width: ${theme.proportion * 11 * theme.mobileResizeFactor}px;
`;

export const TrackingIcon = styled.img`
  width: ${theme.proportion * 28 * theme.mobileResizeFactor}px;
  vertical-align: sub;
  display: inline-block;
  cursor: pointer;
`;

export const FlexButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: ${theme.proportion * 10}px;

  @media only screen and (max-width: 640px) {
    margin-top: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  }
`;

export const StyledCheckbox = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  height: ${theme.proportion * 27 * theme.mobileResizeFactor}px;
  width: ${theme.proportion * 27 * theme.mobileResizeFactor}px;

  & input {
    position: absolute;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  & span {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${theme.biege};
  }

  & span:after {
    content: "";
    position: absolute;
    display: none;
    left: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
    top: ${theme.proportion * 4 * theme.mobileResizeFactor}px;
    width: ${theme.proportion * 8 * theme.mobileResizeFactor}px;
    height: ${theme.proportion * 16 * theme.mobileResizeFactor}px;
    border: solid ${theme.charcoal};
    border-width: 0 ${theme.proportion * 3 * theme.mobileResizeFactor}px
      ${theme.proportion * 3 * theme.mobileResizeFactor}px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  & input:checked ~ span:after {
    display: block;
  }
`;

export const ConWindowFrame = styled.div`
  width: ${theme.proportion * 450 * theme.mobileResizeFactor}px;
  padding: ${theme.proportion * 25 * theme.mobileResizeFactor}px;
  background-color: ${theme.biege};
  box-shadow: 0 ${theme.proportion * 10 * theme.mobileResizeFactor}px
    ${theme.proportion * 25 * theme.mobileResizeFactor}px rgb(0 0 0 / 25%);
`;

export const SearchTitle = styled.div`
  font-family: ${theme.boldFont};
  font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  border-bottom: 1px solid ${theme.biege};
  width: 100%;
  padding-top: ${theme.proportion * 15 * theme.mobileResizeFactor}px;
  padding-bottom: ${theme.proportion * 25 * theme.mobileResizeFactor}px;
  margin-bottom: ${theme.proportion * 25 * theme.mobileResizeFactor}px;

  & span {
    font-size: ${theme.proportion * 24 * theme.mobileResizeFactor}px;
  }
`;

const LoadingAniImg = styled.img`
  width: ${theme.proportion * 120 * theme.mobileResizeFactor}px;
`;

export const LoadingAniC = () => {
  return <LoadingAniImg src={loadingAni} />;
};

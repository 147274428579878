import React, { useState } from "react";
import {
  fileurl,
  SpanBGeneral,
  theme,
  H5Title,
  H6Title,
  ButtonGeneral,
  LoadingAniC,
  InputGeneral,
  ButtonFuncG,
  FullWidthContainer,
  StyledCheckbox,
  ConWindowFrame,
  H2Title,
  PLGeneral,
} from "../../configurations/general-conf";
import styled from "styled-components";
import decorLabel from "../../resources/decor_label.svg";
import decorInter from "../../resources/decor_inter.svg";
import PriceTag from "../widgets/price-tag";
import CurrentStockCache from "../widgets/current-stock-cache";
import CurrentSampleStock from "../widgets/current-sample-stock";
import store from "../../store";
import { getCatsFromProducts } from "../widgets/utilities";
import { confirmAlert } from "react-confirm-alert";
import SimpleConfirmation from "../widgets/simple-confirmation";
import axios from "axios";
import moment from "moment";
import { FlexCheckout } from "../widgets/draft-order";

const ThumbListGrid = styled.ul`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(
    ${(props) => (props.zcolumns ? props.zcolumns : 3)},
    1fr
  );
  grid-row-gap: ${(props) =>
    props.zgridRowGap ? theme.proportion * props.zgridRowGap : 10}px;

  grid-column-gap: ${(props) =>
    props.zgridColumnGap ? theme.proportion * props.zgridColumnGap : 10}px;

  & li {
    border: ${(props) => (props.zborder ? "1px solid " + theme.biege : "none")};
  }

  @media only screen and (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ListItem = styled.li`
  &:hover button {
    display: block;
  }

  &:hover section {
    background-image: ${(props) =>
      props.lifeStyleSrc ? "url(" + fileurl + props.lifeStyleSrc + ")" : null};
  }
`;

const ImgCatCover = styled.img`
  cursor: pointer;
`;

const SwatchContainer = styled.section`
  height: ${(props) =>
    theme.proportion * props.cmHeight * theme.mobileResizeFactor}px;
  background-image: url(${(props) => props.coverSrc});
  background-size: cover;
  background-position: center;
  position: relative;

  & div {
    color: ${theme.bright};
    background: ${theme.astGreen};
    display: inline;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: ${theme.proportion * 4 * theme.mobileResizeFactor}px
      ${theme.proportion * 3 * theme.mobileResizeFactor}px;
    font-family: ${theme.romanFont};
    font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;

    & span {
      font-size: ${theme.proportion * 9 * theme.mobileResizeFactor}px;
      text-transform: lowercase;
    }
  }

  & img {
    position: absolute;
    width: ${theme.proportion * 19 * theme.mobileResizeFactor}px;
    height: ${theme.proportion * 35 * theme.mobileResizeFactor}px;
    bottom: -${theme.proportion * 13 * theme.mobileResizeFactor}px;
    right: -${theme.proportion * 18 * theme.mobileResizeFactor}px;
  }
`;

const DescContainer = styled.div`
  padding: ${theme.proportion * 24 * theme.mobileResizeFactor}px 0
    ${theme.proportion * 5 * theme.mobileResizeFactor}px
    ${theme.proportion * 8 * theme.mobileResizeFactor}px;
  font-family: ${theme.romanFont};
  font-size: ${theme.proportion * 10 * theme.mobileResizeFactor}px;
  position: relative;

  & span {
    font-family: ${theme.boldFont};
    font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
    color: ${theme.darkerBiege};
    margin-right: ${theme.proportion * 3 * theme.mobileResizeFactor}px;
  }

  & img {
    position: absolute;
    width: ${theme.proportion * 35 * theme.mobileResizeFactor}px;
    bottom: ${theme.proportion * 5 * theme.mobileResizeFactor}px;
    right: ${theme.proportion * 8 * theme.mobileResizeFactor}px;
  }
`;

const SampleStockContainer = styled.div`
  padding: ${theme.proportion * 0 * theme.mobileResizeFactor}px 0
    ${theme.proportion * 5 * theme.mobileResizeFactor}px
    ${theme.proportion * 8 * theme.mobileResizeFactor}px;
  line-height: ${theme.proportion * 18 * theme.mobileResizeFactor}px;
  font-family: ${theme.romanFont};
  font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  position: relative;

  & span {
    font-family: ${theme.boldFont};
    font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
    color: ${theme.darkerBiege};
    margin-right: ${theme.proportion * 3 * theme.mobileResizeFactor}px;
  }
`;

const ProductButton = styled(ButtonGeneral)`
  margin: -${theme.proportion * 35 * theme.mobileResizeFactor}px auto ${theme.proportion * 5 * theme.mobileResizeFactor}px;
  width: ${theme.proportion * 192 * theme.mobileResizeFactor}px;
  height: ${theme.proportion * 30 * theme.mobileResizeFactor}px;
  font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  position: relative;
  display: none;

  @media only screen and (max-width: 640px) {
    margin: 0 auto ${theme.proportion * 5 * theme.mobileResizeFactor}px;
    display: block;
    color: ${theme.charcoal};
    background: ${theme.biege};

    &:hover {
      color: ${theme.bright};
      background: ${theme.astGreen};
    }
  }
`;

const StockButton = styled(ButtonGeneral)`
  width: ${theme.proportion * 192 * theme.mobileResizeFactor}px;
  height: ${theme.proportion * 30 * theme.mobileResizeFactor}px;
  font-size: ${theme.proportion * 12 * theme.mobileResizeFactor}px;
  color: ${theme.charcoal};
  background: ${theme.biege};
  margin: 0 auto ${theme.proportion * 5 * theme.mobileResizeFactor}px;
  display: block;

  &:hover {
    color: ${theme.bright};
    background: ${theme.astGreen};
  }
`;

const SmpQtyInput = styled(InputGeneral)`
  width: ${(props) =>
    theme.proportion * props.xwidth * theme.mobileResizeFactor}px;
  height: ${theme.proportion * 22 * theme.mobileResizeFactor}px;
`;

const DateInput = styled(InputGeneral)`
  width: ${(props) =>
    theme.proportion * props.xwidth * theme.mobileResizeFactor}px;
  height: ${theme.proportion * 28 * theme.mobileResizeFactor}px;
  display: block;

  @media only screen and (max-width: 640px) {
    width: ${(props) =>
      theme.proportion * props.xwidth * theme.mobileResizeFactor * 0.8}px;
  }
`;

const StyledCheckboxCat = styled(StyledCheckbox)`
  @media only screen and (max-width: 640px) {
    height: 100%;
    width: 100%;

    & input {
      display: none;
    }

    & span {
      opacity: ${(props) => (props.checked ? 0.7 : 0)};
    }

    & span:after {
      left: ${theme.proportion * 95 * theme.mobileResizeFactor}px;
      top: ${theme.proportion * 20 * theme.mobileResizeFactor}px;
      width: ${theme.proportion * 32 * theme.mobileResizeFactor}px;
      height: ${theme.proportion * 64 * theme.mobileResizeFactor}px;
      border-width: 0 ${theme.proportion * 6 * theme.mobileResizeFactor}px
        ${theme.proportion * 6 * theme.mobileResizeFactor}px 0;
    }
  }
`;

const ThumbListView = (props) => {
  // useEffect(() => {
  //   !store.getState()["draftOrder"] && addDraftOrder(store);
  //   store.dispatch({
  //     type: "reset_draft_order",
  //   });
  // }, []);

  const [dissub, setDissub] = useState(false);
  const [comb, setComb] = useState([]);
  const [coupon, setCoupon] = useState({
    customerUuid: store.getState()["company"]["customerUuid"],
    productCategoryUuid1: null,
    productCategoryUuid2: null,
    discountAmount: 1,
    repeatQty: 1,
    expireDate: moment().add(30, "days").format("YYYY-MM-DD"),
    createBy: store.getState()["company"]["repLogin"],
    modifyBy: store.getState()["company"]["repLogin"],
    notes: "login as rep",
  });
  // const [draftItems, setDraftItems] = useState([]);

  // const handleCombQtyChange = (combItem, qty) => {
  //   const qtyNum = Number(qty);
  //   const newComb = [...comb];
  //   const index = newComb.findIndex(
  //     (item) => item.sampleCombinationUuid === combItem.sampleCombinationUuid
  //   );
  //   if (index >= 0) {
  //     newComb[index].qtyNum = qtyNum;
  //   } else {
  //     newComb.push({ ...combItem, qtyNum });
  //   }
  //   setComb(newComb);

  //   const newDraftItems = [];
  //   newComb.forEach((item) => {
  //     item.products.forEach((product) => {
  //       const productIndex = newDraftItems.findIndex(
  //         (item) => item.productUuid === product.productUuid
  //       );
  //       if (productIndex >= 0) {
  //         newDraftItems[productIndex].quantity += item.qtyNum;
  //         newDraftItems[productIndex].amt = getAmtByQty(
  //           newDraftItems[productIndex].qty,
  //           newDraftItems[productIndex].unitSize
  //         );
  //       } else {
  //         newDraftItems.push({
  //           ...product,
  //           quantity: item.qtyNum,
  //           amt: getAmtByQty(item.qtyNum, item.unitSize),
  //         });
  //       }
  //     });
  //   });
  //   setDraftItems(newDraftItems);
  // };

  const handleCombQtyChange = (combItem, qty) => {
    const quantity = Number(qty);
    const newComb = [...comb];
    const index = newComb.findIndex(
      (item) => item.sampleCombinationUuid === combItem.sampleCombinationUuid
    );
    if (index >= 0) {
      newComb[index].quantity = quantity;
    } else {
      newComb.push({
        sampleCombinationUuid: combItem.sampleCombinationUuid,
        smapleInfo: combItem,
        quantity,
      });
    }
    setComb(newComb);

    // console.log(newComb);
  };

  // const createSampleDraft = async () => {
  //   if (draftItems.length > 0) {
  //     setDissub(true);
  //     // const postItem = {
  //     //   customerDeliveryTyp: 2,
  //     //   customerUuid: store.getState()["company"]["customerUuid"],
  //     //   status: 100,
  //     //   info2: "Sample Order " + Math.floor(Date.now() / 1000),
  //     //   saleItems: draftItems,
  //     // };
  //     const postItem = {
  //       info2: "Sample Order " + Math.floor(Date.now() / 1000),
  //       customerDeliveryType: 2,
  //       saleItems: draftItems,
  //       customerUuid: store.getState()["company"]["customerUuid"],
  //       status: 100,
  //     };
  //     // console.log(postItem);
  //     const res = await axios.post("/saleorders/new", postItem);
  //     if (res.data.result === 1) {
  //       confirmAlert({
  //         customUI: ({ onClose }) => {
  //           return (
  //             <SimpleConfirmation
  //               title="Sample Draft Created"
  //               message="A draft of the sample order has been successfully created."
  //               onClose={onClose}
  //             />
  //           );
  //         },
  //       });
  //       props.history.push("/sampleorder");
  //     } else {
  //       confirmAlert({
  //         customUI: ({ onClose }) => {
  //           return (
  //             <SimpleConfirmation
  //               title="Oops..."
  //               message={res.data.msg}
  //               onClose={onClose}
  //             />
  //           );
  //         },
  //       });
  //       setDissub(false);
  //     }
  //   }
  // };

  // console.log(props.list);

  const createSampleDraft = async () => {
    if (comb.length > 0) {
      setDissub(true);
      const postItem = {
        // info2: "Sample Order " + Math.floor(Date.now() / 1000),
        info2: Math.floor(Date.now() / 1000),
        customerDeliveryType: 2,
        sampleItems: comb,
        customerUuid: store.getState()["company"]["customerUuid"],
        status: 100,
      };
      // console.log(postItem);
      const res = await axios.post("/sampleorders/sampleitem/new", postItem);
      // console.log(res.data);
      if (res.data.result === 1) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <SimpleConfirmation
                title="Sample Draft Created"
                message="A draft of the sample order has been successfully created."
                onClose={onClose}
              />
            );
          },
        });
        props.history.push(
          "/sampleorder/createnew/draftorder/" + res.data.objs[0]?.saleOrderUuid
        );
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <SimpleConfirmation
                title="Oops..."
                message={res.data.msg}
                onClose={onClose}
              />
            );
          },
        });
        setDissub(false);
      }
    }
  };

  const addSampleOrder = () => {
    if (comb.length > 0) {
      store.dispatch({
        type: "add_draft_sample_items",
        sampleItems: comb,
      });
      const to =
        "/sampleorder/createnew/draftorder/" +
        store.getState()["draftOrder"]?.saleOrderUuid;
      props.history.push(to);
    }
  };

  const createCouponCode = async () => {
    if (
      coupon.productCategoryUuid1 !== null ||
      coupon.productCategoryUuid2 !== null
    ) {
      setDissub(true);
      const res = await axios.post("/promotions/new", coupon);
      if (res.data.result === 1) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <SimpleConfirmation
                title="Coupon Code Created"
                message="A coupon code has been successfully created."
                onClose={onClose}
              />
            );
          },
        });
        props.history.push("/promotioncodes");
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <SimpleConfirmation
                title="Oops..."
                message={res.data.msg}
                onClose={onClose}
              />
            );
          },
        });
        setDissub(false);
      }
    }
  };

  const handleCatsSelect = (flag, id) => {
    const newCoupon = { ...coupon };
    if (flag) {
      if (newCoupon.productCategoryUuid1 === null) {
        newCoupon.productCategoryUuid1 = id;
      } else {
        newCoupon.productCategoryUuid2 = id;
      }
    } else {
      newCoupon.productCategoryUuid1 =
        newCoupon.productCategoryUuid1 === id
          ? null
          : newCoupon.productCategoryUuid1;
      newCoupon.productCategoryUuid2 =
        newCoupon.productCategoryUuid2 === id
          ? null
          : newCoupon.productCategoryUuid2;
    }
    setCoupon(newCoupon);
  };

  const handleDateChange = (e) => {
    setCoupon({ ...coupon, expireDate: e.target.value });
    if (
      e.target.value > moment().add(30, "days").format("YYYY-MM-DD") ||
      e.target.value < moment().format("YYYY-MM-DD")
    ) {
      confirmAlert({
        closeOnClickOutside: false,
        closeOnEscape: false,
        customUI: ({ onClose }) => {
          return (
            <ConWindowFrame>
              <H2Title xfontSize="20" xmarginBottom="24">
                Date Range Reminder
              </H2Title>
              <PLGeneral xfontSize="14" xlineHeight="18" xmarginBottom="24">
                Please select a date within 30 days from today.
              </PLGeneral>
              <ButtonFuncG
                onClick={() => {
                  setCoupon({
                    ...coupon,
                    expireDate: moment().add(30, "days").format("YYYY-MM-DD"),
                  });
                  onClose();
                }}
              >
                Close
              </ButtonFuncG>
            </ConWindowFrame>
          );
        },
      });
    }
  };

  const { list, listCfg, imgClick, toProduct, dyPath } = props;
  // console.log(store.getState()["draftOrder"]);
  const saleOrderUuid = store.getState()["draftOrder"]?.saleOrderUuid;
  // console.log(props);
  let cats = [];
  if (props.dyPath === "/promotioncodes/createnew") {
    cats = getCatsFromProducts(list);
  }
  // console.log(coupon);
  return (
    <>
      {list?.length > 0 && (
        <>
          <ThumbListGrid
            zborder={listCfg.itemBorder}
            zgridRowGap={listCfg.rowGap}
            zgridColumnGap={listCfg.columnGap}
            zcolumns={listCfg.colCount}
          >
            {cats.length === 0 &&
              list.map((listItem) => {
                return (
                  <ListItem
                    key={listItem[listCfg.mainKey]}
                    lifeStyleSrc={listItem[listCfg.lifeStyleKey]}
                  >
                    {listCfg.columns.map((column, i) => {
                      const key = `${listItem[listCfg.mainKey]}_data_${i}`;
                      switch (column.type) {
                        case "cat_cover":
                          const imgSrc = fileurl + listItem[column.key];
                          const imgAlt = listItem[column.altKey];
                          return (
                            <ImgCatCover
                              key={key}
                              src={imgSrc}
                              alt={imgAlt}
                              onClick={
                                imgClick
                                  ? () => {
                                      imgClick(listItem[listCfg.mainKey]);
                                    }
                                  : null
                              }
                            />
                          );
                        case "swatch":
                          const coverSrc = fileurl + listItem[column.coverKey];
                          return (
                            <SwatchContainer
                              key={key}
                              coverSrc={coverSrc}
                              cmHeight={dyPath === "/checklevel" ? 85 : 140}
                            >
                              {dyPath !== "/checklevel" &&
                                dyPath.indexOf("/sampleorder") < 0 && (
                                  <PriceTag
                                    unitSymbol={
                                      listItem[column.labelSuffixKey]?.split(
                                        "|"
                                      )[0]
                                    }
                                    unitNanme={
                                      listItem[column.labelSuffixKeyAlt]?.split(
                                        "|"
                                      )[0]
                                    }
                                    unitSize={listItem[column.factorKey]}
                                    perText={listItem[column.labelSuffix]}
                                    price={listItem[column.labelKey]}
                                  />
                                )}
                              {dyPath !== "/checklevel" &&
                                column.decorLabel && (
                                  <img src={decorLabel} alt="Label Decor" />
                                )}
                              {dyPath.indexOf("/sampleorder") > -1 && (
                                <SmpQtyInput
                                  type="number"
                                  step="1"
                                  min="0"
                                  id={`idQty_${list["sampleCombinationUuid"]}`}
                                  name={`nameQty_${list["sampleCombinationUuid"]}`}
                                  key={`keyQty_${list["sampleCombinationUuid"]}`}
                                  xwidth="60"
                                  placeholder="qty:"
                                  onBlur={(e) =>
                                    handleCombQtyChange(
                                      listItem,
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                            </SwatchContainer>
                          );
                        case "title":
                          const iTitle =
                            listItem[column.key] ||
                            listItem[column.altKey] ||
                            listItem[column.altKey2];
                          return (
                            <H5Title
                              key={key}
                              xfontSize="12"
                              xmarginLeft="8"
                              xmarginTop="8"
                              xmarginBottom={column.marginBottom || 0}
                            >
                              {iTitle}
                            </H5Title>
                          );
                        case "sub_title":
                          const iSubTitle = listItem[column.key];
                          return (
                            <H6Title
                              key={key}
                              xfontSize="12"
                              xmarginLeft="8"
                              xmarginTop="5"
                              xcolor="darkerBiege"
                            >
                              {iSubTitle}
                            </H6Title>
                          );
                        case "description":
                          const iDescription = listItem[column.key];
                          return (
                            <DescContainer key={key}>
                              <span>{column.prefix}</span>
                              {iDescription}
                              {dyPath !== "/checklevel" &&
                                column.decorInter && (
                                  <img src={decorInter} alt="Inter Decor" />
                                )}
                            </DescContainer>
                          );
                        default:
                          const iValue = listItem[column.key];
                          return <span key={key}>{iValue}</span>;
                      }
                    })}
                    {dyPath === "/checklevel" && (
                      <DescContainer>
                        <CurrentStockCache data={listItem} />
                      </DescContainer>
                    )}
                    {dyPath === "/sampleorder/createnew" && (
                      <SampleStockContainer>
                        <CurrentSampleStock data={listItem} />
                      </SampleStockContainer>
                    )}
                    {dyPath !== "/checklevel" &&
                      dyPath !== "/sampleorder/createnew" &&
                      toProduct && (
                        <ProductButton
                          onClick={() => {
                            toProduct(listItem[listCfg.mainKey]);
                          }}
                        >
                          SELECT
                        </ProductButton>
                      )}
                    {dyPath === "/checklevel" && toProduct && (
                      <StockButton
                        onClick={() => {
                          toProduct(listItem[listCfg.mainKey]);
                        }}
                      >
                        SEE MORE
                      </StockButton>
                    )}
                  </ListItem>
                );
              })}
            {cats.length > 0 &&
              cats.map((catItem) => {
                const checked =
                  catItem.productCategoryUuid === coupon.productCategoryUuid1 ||
                  catItem.productCategoryUuid === coupon.productCategoryUuid2;
                return (
                  <ListItem key={catItem.productCategoryUuid}>
                    <SwatchContainer
                      coverSrc={fileurl + catItem.productcCategoryImage}
                      cmHeight={dyPath === "/checklevel" ? 85 : 140}
                      onClick={() => {
                        // console.log(checked);
                        handleCatsSelect(!checked, catItem.productCategoryUuid);
                      }}
                      title={catItem.productCategoryName}
                    >
                      <StyledCheckboxCat checked={checked}>
                        <input
                          type="checkbox"
                          id={`catCheckbox_${catItem.productCategoryUuid}`}
                          name={`catCheckbox_${catItem.productCategoryUuid}`}
                          key={`catCheckbox_${catItem.productCategoryUuid}`}
                          checked={checked}
                          disabled={true}
                          onChange={(e) => e.preventDefault()}
                        />
                        <span></span>
                      </StyledCheckboxCat>
                    </SwatchContainer>
                    {/* <H5Title
                      xfontSize="12"
                      xmarginLeft="8"
                      xmarginTop="8"
                      xmarginBottom="8"
                    >
                      {catItem.productCategoryName}
                    </H5Title> */}
                  </ListItem>
                );
              })}
          </ThumbListGrid>
          {dyPath?.indexOf("/sampleorder") > -1 && (
            <FullWidthContainer xmarginTop="10">
              {saleOrderUuid ? (
                <ButtonFuncG onClick={() => addSampleOrder()}>
                  Add to Sample Order
                </ButtonFuncG>
              ) : (
                <ButtonFuncG
                  onClick={() => createSampleDraft()}
                  disabled={dissub}
                >
                  Create Sample Order
                </ButtonFuncG>
              )}
            </FullWidthContainer>
          )}
          {dyPath === "/promotioncodes/createnew" && (
            <FullWidthContainer xmarginTop="10">
              <H5Title xfontSize="12" xmarginTop="20" xmarginBottom="10">
                Expiry Date
              </H5Title>
              <DateInput
                type="date"
                id="expireDate"
                name="expireDate"
                xwidth="210"
                min={moment().format("YYYY-MM-DD")}
                max={moment().add(30, "days").format("YYYY-MM-DD")}
                key={`expireDate_${coupon.expireDate}`}
                defaultValue={coupon.expireDate}
                // onBlur={(e) => {
                //   setCoupon({
                //     ...coupon,
                //     expireDate:
                //       e.target.value !== "" &&
                //       e.target.value >= moment().format("YYYY-MM-DD") &&
                //       e.target.value <=
                //         moment().add(30, "days").format("YYYY-MM-DD")
                //         ? e.target.value
                //         : moment().add(30, "days").format("YYYY-MM-DD"),
                //   });
                // }}
                onBlur={(e) => handleDateChange(e)}
                required
              />
              <FlexCheckout xpaddingTop="30">
                <FlexCheckout>
                  <SpanBGeneral xfontSize="14" xmarginRight="10">
                    $0.50 off
                  </SpanBGeneral>
                  <StyledCheckbox>
                    <input
                      type="checkbox"
                      id="oneoff"
                      name="oneoff"
                      key={`oneoff_${coupon.discountAmount}`}
                      defaultChecked={coupon.discountAmount === 0.5}
                      disabled={coupon.discountAmount === 0.5}
                      onChange={() =>
                        setCoupon({ ...coupon, discountAmount: 0.5 })
                      }
                    />
                    <span></span>
                  </StyledCheckbox>
                </FlexCheckout>
                <FlexCheckout xmarginLeft="60">
                  <SpanBGeneral xfontSize="14" xmarginRight="10">
                    $1.00 off
                  </SpanBGeneral>
                  <StyledCheckbox>
                    <input
                      type="checkbox"
                      id="oneoff"
                      name="oneoff"
                      key={`oneoff_${coupon.discountAmount}`}
                      defaultChecked={coupon.discountAmount === 1}
                      disabled={coupon.discountAmount === 1}
                      onChange={() =>
                        setCoupon({ ...coupon, discountAmount: 1 })
                      }
                    />
                    <span></span>
                  </StyledCheckbox>
                </FlexCheckout>
                <FlexCheckout xmarginLeft="60">
                  <SpanBGeneral xfontSize="14" xmarginRight="10">
                    $1.50 off
                  </SpanBGeneral>
                  <StyledCheckbox>
                    <input
                      type="checkbox"
                      id="twooff"
                      name="twooff"
                      key={`twooff_${coupon.discountAmount}`}
                      defaultChecked={coupon.discountAmount === 1.5}
                      disabled={coupon.discountAmount === 1.5}
                      onChange={() =>
                        setCoupon({ ...coupon, discountAmount: 1.5 })
                      }
                    />
                    <span></span>
                  </StyledCheckbox>
                </FlexCheckout>
              </FlexCheckout>
              <FlexCheckout xpaddingTop="30">
                <FlexCheckout>
                  <SpanBGeneral xfontSize="14" xmarginRight="10">
                    $2.00 off
                  </SpanBGeneral>
                  <StyledCheckbox>
                    <input
                      type="checkbox"
                      id="oneoff"
                      name="oneoff"
                      key={`oneoff_${coupon.discountAmount}`}
                      defaultChecked={coupon.discountAmount === 2.00}
                      disabled={coupon.discountAmount === 2.00}
                      onChange={() =>
                        setCoupon({ ...coupon, discountAmount: 2.00 })
                      }
                    />
                    <span></span>
                  </StyledCheckbox>
                </FlexCheckout>
                <FlexCheckout xmarginLeft="60">
                  <SpanBGeneral xfontSize="14" xmarginRight="10">
                    $2.50 off
                  </SpanBGeneral>
                  <StyledCheckbox>
                    <input
                      type="checkbox"
                      id="twooff"
                      name="twooff"
                      key={`twooff_${coupon.discountAmount}`}
                      defaultChecked={coupon.discountAmount === 2.50}
                      disabled={coupon.discountAmount === 2.50}
                      onChange={() =>
                        setCoupon({ ...coupon, discountAmount: 2.50 })
                      }
                    />
                    <span></span>
                  </StyledCheckbox>
                </FlexCheckout>
                <FlexCheckout xmarginLeft="60">
                  <SpanBGeneral xfontSize="14" xmarginRight="10">
                    $3.00 off
                  </SpanBGeneral>
                  <StyledCheckbox>
                    <input
                      type="checkbox"
                      id="twooff"
                      name="twooff"
                      key={`twooff_${coupon.discountAmount}`}
                      defaultChecked={coupon.discountAmount === 3.00}
                      disabled={coupon.discountAmount === 3.00}
                      onChange={() =>
                        setCoupon({ ...coupon, discountAmount: 3.00 })
                      }
                    />
                    <span></span>
                  </StyledCheckbox>
                </FlexCheckout>
              </FlexCheckout>
              <ButtonFuncG xmarginTop="30" onClick={() => createCouponCode()}>
                Create Coupon Code
              </ButtonFuncG>
            </FullWidthContainer>
          )}
        </>
      )}
      {list?.length === 0 && (
        <SpanBGeneral xfontSize="14">
          {listCfg?.noDataInfo ? listCfg?.noDataInfo : "No Data."}
        </SpanBGeneral>
      )}
      {!list && <LoadingAniC />}
    </>
  );
};

export default ThumbListView;
